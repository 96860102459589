<template>
  <profile-layout>
    <card>
      <validation-observer ref="form">
        <height class="card__section" />
        <!-- <weight class="card__section" /> -->
        <chronic-conditions class="card__section" />
        <smoke class="card__section"  :short="true" />
        <vape class="card__section"  :short="true" />
        <div class="page__card-footer">
          <button
            class="button button_secondary page__card-footer-button"
            :disabled="loading"
            @click="validate(nextHandler)"
          >
            <span>Save changes</span>
          </button>
        </div>
      </validation-observer>
    </card>
  </profile-layout>
</template>

<script>
import ProfileLayout from "@/components/profile/profileLayout";
import Card from "@/components/card";
import Height from "@/components/characteristics/height";
// import Weight from "@/components/characteristics/weight";
import ChronicConditions from "@/components/characteristics/chronicCondition";
import Smoke from "@/components/characteristics/smoke";
import Vape from "@/components/characteristics/vape";
import ValidateMixin from "@/mixins/validate";
import logVisiting from "@/mixins/logVisiting";

import { mapActions, mapState } from "vuex";
import { saveLog } from "@/modules/logger";
import {
  STORE_SAVE_CHARACTERISTIC,
  STORE_GET_CHARACTERISTICS,
  POPUP_NOTIFICATION,
  MSG_PARTICIPANT_SAVED,
  ROUTE_PROFILE_DASHBOARD
} from "@/constants";

export default {
  name: "helthDataPage",

  components: {
    ProfileLayout,
    Card,
    Height,
    // Weight,
    ChronicConditions,
    Smoke,
    Vape
  },

  mixins: [ValidateMixin, logVisiting],

  inject: {
    showModal: { default: () => {} }
  },

  computed: {
    ...mapState({
      loading: "loading",
      chronic_conditions: "chronic_conditions",
      dob_year: ({ characteristics }) => characteristics.dob_year,
      dob_month: ({ characteristics }) => characteristics.dob_month,
      height: ({ characteristics }) => characteristics.height,
      // weight: ({ characteristics }) => characteristics.weight,
      smoke: ({ characteristics }) => characteristics.smoke,
      smoke_value: ({ characteristics }) => characteristics.smoke_value,
      vape: ({ characteristics }) => characteristics.vape,
      vape_value_ml: ({ characteristics }) => characteristics.vape_value_ml,
      vape_value_mg: ({ characteristics }) => characteristics.vape_value_mg
    })
  },

  created() {
    this.getChars();
  },

  methods: {
    ...mapActions([STORE_SAVE_CHARACTERISTIC, STORE_GET_CHARACTERISTICS]),

    nextHandler() {
      saveLog("health-form-complete");
      this.saveChars().then(this.showSuccessfulPopup);
    },

    saveChars() {
      return this[STORE_SAVE_CHARACTERISTIC]({
        height: this.height,
        // weight: this.weight,
        smoke: this.smoke,
        smoke_value: this.smoke_value,
        vape: this.vape,
        vape_value_ml: this.vape_value_ml,
        vape_value_mg: this.vape_value_mg,
        dob_month: this.dob_month,
        dob_year: this.dob_year,
        ...this.chronic_conditions
      });
    },

    getChars() {
      this[STORE_GET_CHARACTERISTICS]();
    },

    showSuccessfulPopup() {
      this.showModal(POPUP_NOTIFICATION, {
        title: MSG_PARTICIPANT_SAVED,
        icon: "check",
        handleClose: () => {
          this.$router.push({ name: ROUTE_PROFILE_DASHBOARD }, null, () => {});
        }
      });
    }
  }
};
</script>
